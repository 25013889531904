.faq {
    max-width: 800px;
    margin: auto;
    padding: 2rem;
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
    color: #333333;
  }
  
  .faq-item {
    margin-bottom: 1.5rem;
  }
  
  .faq-item h3 {
    margin-bottom: 0.5rem;
  }
  