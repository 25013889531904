body {
  margin: 0;
  font-family: Helvetica, sans-serif;
}

header {
  background-color: white;
  color: #333333;
  padding: 1rem;
  text-align: center;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  letter-spacing: 0.15em;
  /*text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);*/
  font-size: 12px;
}

nav ul {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  font-size: 14px;
}

nav ul li {
  margin: 0 1rem;
}

nav ul li a.current-page {
  font-weight: bold;
}

nav ul li a {
  color: black;
  text-decoration: none;
}

main {
  padding: 2rem;
}

footer {
  background-color: white;
  color: black;
  text-align: center;
  padding: 1rem;
  position: static;
  width: 100%;
  height: 50px;
  bottom: 0;
  padding-bottom: 100px;
  font-family: 'Montserrat', Arial, Helvetica, sans-serif;
}

footer a {
  color: #333333;
  font-size: 30px;
  margin: 0 0.5rem;
  text-decoration: none;
}
footer svg {
  width: 50px; /* Adjust the width to make icons bigger */
  height: 50px; /* Adjust the height to make icons bigger */
}

@media (max-width: 600px) {
  nav ul {
    flex-direction: column;
  }

  footer {
    position: static;
  }
}
