.submissions {
    max-width: 800px;
    margin: auto;
    padding: 2rem;
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
    color: #333333;
  }
  
  .submissions p {
    margin-bottom: 1rem;
    line-height: 1.6;
  }
  