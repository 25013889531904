.contact {
    max-width: 600px;
    margin: auto;
    padding: 2rem;
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
    color: #333333;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  label {
    display: block;
    margin-bottom: 0.5rem;
  }
  
  input, textarea {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
  }
  
  button {
    padding: 0.5rem 1rem;
    background-color: #333;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
    border: 1px solid #333;
  }
  
  button:hover {
    background-color: white;
    color: #333;
    border: 1px solid #333; 
  }